export function ensureInteger(valueRaw, defaultValue) {
  const value = parseInt(valueRaw, 10)
  return isNaN(value) ? defaultValue : value
}

export function ensureNumber(valueRaw, defaultValue) {
  const value = parseFloat(valueRaw)
  return isNaN(value) ? defaultValue : value
}

export function ensureString(value, defaultValue = '') {
  if (!value || Array.isArray(value)) return defaultValue
  return String(value)
}

export function ensureArray(value) {
  if (!Array.isArray(value)) return []
  return value
}

export function ensureArrayOfNumbers(value) {
  if (!Array.isArray(value)) return []
  return value.map(x => ensureNumber(x, 0))
}

export function ensureBoolean(value) {
  if (value === 'true') return true
  if (value === 'false') return false
  return Boolean(value)
}
